import React, { useState } from "react";

const QuestionCard = ({ question, isActive, onAnswerSubmit }) => {
  const [content, setContent] = useState([
    { label: "Question", text: question.question },
  ]);
  const [stage, setStage] = useState(1);
  const [userAnswer, setUserAnswer] = useState("");
  const [isAnswering, setIsAnswering] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [warningAcknowledged, setWarningAcknowledged] = useState(false);

  const stages = [
    { label: "Question", text: question.question },
    { label: "Hint", text: question.hint },
    { label: "Short Answer", text: question.shortAnswer },
    { label: "Full Answer", text: question.longAnswer },
  ];

  const handleExpandClick = () => {
    if (stage === 2 && !warningAcknowledged) {
      setWarningAcknowledged(true);
      if (
        !window.confirm(
          "Displaying the short answer will disable answering this question. Do you want to proceed?"
        )
      ) {
        return;
      }
    }
    if (stage < stages.length) {
      setContent([...content, stages[stage]]);
      setStage(stage + 1);
      if (stage === 2) {
        setIsAnswering(false);
      }
    }
    setIsExpanded(true);
  };

  const handleSubmit = () => {
    onAnswerSubmit({ questionId: question.id, stage, answer: userAnswer });
    setIsAnswering(false);
  };

  return (
    <div
      className={`bg-white shadow-md rounded-lg p-6 m-4 transition duration-300 transform ${
        isActive ? "hover:scale-105" : "blur-sm"
      }`}
    >
      {isActive ? (
        <>
          {content.map((item, index) => (
            <div key={index} className="mb-2">
              <span className="font-bold">{item.label}: </span>
              <p className="text-gray-800 text-lg">{item.text}</p>
            </div>
          ))}
          {isAnswering && (
            <div className="mt-4">
              <label
                htmlFor={`user-answer-${question.id}`}
                className="block text-gray-700 font-bold mb-2"
              >
                Your Answer:
              </label>
              <input
                id={`user-answer-${question.id}`}
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={userAnswer}
                onChange={(e) => setUserAnswer(e.target.value)}
              />
              <button
                className="mt-2 bg-blue-500 text-white py-2 px-4 rounded"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          )}
          <button
            className="mt-2 bg-green-500 text-white py-2 px-4 rounded"
            onClick={handleExpandClick}
          >
            {stage === 1
              ? "Show Hint"
              : stage === 2
              ? "Show Short Answer"
              : "Show Full Answer"}
          </button>
        </>
      ) : (
        <div className="text-center text-gray-500">Question {question.id}</div>
      )}
    </div>
  );
};

export default QuestionCard;
