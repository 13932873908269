import React, { useEffect, useState } from "react";

const Timer = ({ start }) => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(Math.floor((Date.now() - start) / 1000));
    }, 1000);

    return () => clearInterval(timer);
  }, [start]);

  return (
    <div className="fixed top-4 right-4 bg-white p-4 rounded shadow">
      <p className="text-gray-800 font-bold">Time Elapsed: {time} seconds</p>
    </div>
  );
};

export default Timer;
