import React, { useState, useEffect } from "react";
import QuestionsGrid from "./components/QuestionsGrid";
import Timer from "./components/Timer";
import { v4 as uuidv4 } from "uuid";
import "./index.css";

// Import JSON files
import csharpGeneral from "./sources/csharp_general_interview_questions.json";
import csharpGeneral2nd from "./sources/csharp_general_interview_questions_2nd.json";
import csharp8 from "./sources/csharp_8_interview_questions.json";
import nodejsGeneral from "./sources/nodejs_general_interview_questions.json";
import reactjsGeneral from "./sources/reactjs_general_interview_questions.json";

const App = () => {
  const [selectedTopic, setSelectedTopic] = useState("");
  const [questions, setQuestions] = useState([]);
  const [sessionStarted, setSessionStarted] = useState(false);
  const [visibleQuestions, setVisibleQuestions] = useState([]);
  const [sessionStart, setSessionStart] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [sessions, setSessions] = useState(
    JSON.parse(localStorage.getItem("sessions")) || []
  );
  const [candidate, setCandidate] = useState({
    name: localStorage.getItem("candidateName") || "",
    email: localStorage.getItem("candidateEmail") || "",
  });
  const [warningAcknowledged, setWarningAcknowledged] = useState(false);

  const topics = {
    "C# General": csharpGeneral,
    "C# General 2nd Edition": csharpGeneral2nd,
    "C# 8": csharp8,
    "Node.js": nodejsGeneral,
    "React.js": reactjsGeneral,
  };

  useEffect(() => {
    if (selectedTopic) {
      const shuffledQuestions = topics[selectedTopic].questions.sort(
        () => 0.5 - Math.random()
      );
      setQuestions(shuffledQuestions);
    }
  }, [selectedTopic]);

  useEffect(() => {
    localStorage.setItem("candidateName", candidate.name);
    localStorage.setItem("candidateEmail", candidate.email);
  }, [candidate]);

  useEffect(() => {
    const handleUnload = () => {
      if (sessionStarted) {
        saveSession();
      }
    };

    window.addEventListener("beforeunload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [sessionStarted, answers]);

  const handleStartSession = () => {
    setSessionStarted(true);
    setSessionStart(Date.now());
    setVisibleQuestions(3);
  };

  const handleAnswerSubmit = (answer) => {
    setAnswers([...answers, answer]);
    if (answers.length + 1 >= visibleQuestions) {
      setVisibleQuestions(Math.min(visibleQuestions + 3, questions.length));
    }
  };

  const handleFinishSession = () => {
    if (answers.length < visibleQuestions) {
      alert(
        "There are questions pending to be answered. Please answer or expand all questions before finishing."
      );
      return;
    }

    saveSession();
    setSessionStarted(false);
    setAnswers([]);
    setCandidate({ name: "", email: "" });
    setVisibleQuestions(0);
    alert("Thank you! The session has been completed.");
  };

  const saveSession = () => {
    const sessionEnd = Date.now();
    const newSession = {
      id: uuidv4(),
      candidate,
      selectedTopic,
      sessionStart: new Date(sessionStart).toISOString(),
      sessionEnd: new Date(sessionEnd).toISOString(),
      answers: answers.map((answer) => ({
        ...answer,
        questionSentence: questions.find((q) => q.id === answer.questionId)
          .question,
      })),
    };

    const updatedSessions = [...sessions, newSession];
    setSessions(updatedSessions);
    localStorage.setItem("sessions", JSON.stringify(updatedSessions));
  };

  const handleLeaveSession = () => {
    if (window.confirm("Are you sure you want to leave the session?")) {
      saveSession();
      setSessionStarted(false);
      setAnswers([]);
      setVisibleQuestions(0);
    }
  };

  const handleWarningAcknowledged = () => {
    setWarningAcknowledged(true);
  };

  const [expandedSessions, setExpandedSessions] = useState([]);

  const toggleSession = (id) => {
    setExpandedSessions(
      expandedSessions.includes(id)
        ? expandedSessions.filter((sessionId) => sessionId !== id)
        : [...expandedSessions, id]
    );
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container mx-auto p-6">
        <h1 className="text-3xl font-bold mb-4">Interview Session</h1>
        <p className="mb-4">
          Welcome! Follow the instructions to complete your interview session.
          Answer or expand each question to proceed.
        </p>
        <div className="mb-4">
          <label
            htmlFor="topic-select"
            className="block text-lg font-medium text-gray-700"
          >
            Select Topic:
          </label>
          <select
            id="topic-select"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            onChange={(e) => setSelectedTopic(e.target.value)}
            value={selectedTopic}
          >
            <option value="">Select a topic</option>
            {Object.keys(topics).map((topic) => (
              <option key={topic} value={topic}>
                {topic}
              </option>
            ))}
          </select>
        </div>
        {selectedTopic && !sessionStarted && (
          <div>
            <label className="block text-lg font-medium text-gray-700">
              Candidate Name:
            </label>
            <input
              type="text"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={candidate.name}
              onChange={(e) =>
                setCandidate({ ...candidate, name: e.target.value })
              }
            />
            <label className="block text-lg font-medium text-gray-700">
              Candidate Email:
            </label>
            <input
              type="email"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              value={candidate.email}
              onChange={(e) =>
                setCandidate({ ...candidate, email: e.target.value })
              }
            />
            <button
              className="mt-2 bg-green-500 text-white py-2 px-4 rounded"
              onClick={handleStartSession}
            >
              Start Session
            </button>
          </div>
        )}
        {sessionStarted && (
          <>
            <QuestionsGrid
              questions={questions}
              activeIndex={visibleQuestions}
              onAnswerSubmit={handleAnswerSubmit}
              onWarningAcknowledged={handleWarningAcknowledged}
            />
            <Timer start={sessionStart} />
            <button
              className="mt-2 bg-red-500 text-white py-2 px-4 rounded"
              onClick={handleLeaveSession}
            >
              Leave Session
            </button>
            {visibleQuestions >= questions.length && (
              <button
                className="mt-2 bg-red-500 text-white py-2 px-4 rounded"
                onClick={handleFinishSession}
              >
                Finish Session
              </button>
            )}
          </>
        )}
        <div className="mt-4">
          <h2 className="text-2xl font-bold mb-2">Previous Sessions</h2>
          {sessions.map((session) => (
            <div key={session.id} className="mb-4">
              <button
                className="w-full text-left bg-gray-300 p-4 rounded"
                onClick={() => toggleSession(session.id)}
              >
                Session on {new Date(session.sessionStart).toLocaleString()} -{" "}
                {session.candidate.name}
              </button>
              {expandedSessions.includes(session.id) && (
                <pre className="bg-gray-200 p-4 rounded shadow mt-2">
                  {JSON.stringify(session, null, 2)}
                </pre>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default App;
