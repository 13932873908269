import React from "react";
import QuestionCard from "./QuestionCard";

const QuestionsGrid = ({ questions, activeIndex, onAnswerSubmit }) => {
  return (
    <div className="container mx-auto p-4">
      <div className="flex flex-wrap -mx-4">
        {questions.map((question, index) => (
          <div
            key={index}
            className="w-full sm:w-1/2 md:w-1/3 lg:w-1/2 px-4 mb-8"
          >
            <QuestionCard
              question={question}
              isActive={true}
              onAnswerSubmit={onAnswerSubmit}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuestionsGrid;
